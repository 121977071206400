<template>
  <div>
    <div class="_BoxHeader">
      <span>区域管理</span>
    </div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <span class="_Title">信息列表</span>
        <div class="_Select">
          <span style="width: 65px">公司：</span>
          <el-select
            v-model="compyValue"
            placeholder="请选择"
            @change="changCompyInp"
          >
            <el-option
              v-for="item in cpmpyList"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Inp">
          <span>区域名称/编号：</span>
          <el-input placeholder="请输入区域名称/区域编号" v-model="areaVal" />
        </div>
        <el-button
          type="success"
          icon="el-icon-search"
          @click="getList('AreaNo')"
          >搜索</el-button
        >
      </div>
      <div class="tow-box">
        <div>
          <el-table
            :data="isSearch ? searchLits : regionList"
            style="width: 100%"
            :header-cell-style="{ background: '#E2E2E2', color: '#5C5C5C' }"
            stripe
            :max-height="elementHeight"
            id="table"
          >
            <el-table-column type="index" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="areaNo" label="区域编号" width="180">
            </el-table-column>
            <el-table-column prop="areaName" label="区域名称">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <i
                  class="el-icon-delete"
                  @click="deleteItem(scope.row, scope.$index)"
                ></i>
                <i class="el-icon-edit" @click="editItem(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <p>{{ isAdd ? '添加区域' : '修改区域' }}</p>
          <div class="_Inp">
            <span>区域编号：</span>
            <el-input placeholder="请输入" v-model="areaNo" />
          </div>
          <div class="_Inp">
            <span>区域名称：</span>
            <el-input placeholder="请输入" v-model="areaName" />
          </div>
          <el-button type="primary" @click="addRegion">确定</el-button>
          <el-button v-if="!isAdd" @click="cancle">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      areaVal: '',
      areaNo: '',
      areaName: '',
      tableData: [],
      value: '',
      regionList: [],
      isAdd: true,
      rowItem: {},
      searchLits: [],
      isSearch: false,
      cpmpyList: [],
      compyValue: '',
    }
  },
  mounted() {
    this.getElementHeight()
    this.getCompy()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52 + 50)
      })
    },
    getCompy() {
      get('/api/Company/GetAll').then((resp) => {
        if (resp.code == 200) {
          this.cpmpyList = resp.data
          this.compyValue = resp.data[0].id
          this.getRegionInfo()
        }
      })
    },
    changCompyInp() {
      this.getRegionInfo()
    },
    getList(key) {
      this.regionList = []
      this.areaVal == '' ? this.getRegionInfo() : this.searchList(key)
    },
    searchList(key) {
      get('/api/Area/GetByArea?' + key + '=' + this.areaVal).then((resp) => {
        if (resp.code == 200) {
          if (resp.data.length == 0 && key == 'AreaNo') {
            this.searchList('AreaName')
          } else {
            this.regionList = resp.data
          }
        }
      })
    },
    getRegionInfo() {
      this.areaVal = ''
      get('/api/Area/GetByCompId?CompId=' + this.compyValue).then((resp) => {
        if (resp.code == 200) {
          this.regionList = resp.data
        }
      })
    },

    cancle() {
      this.isAdd = true
      this.areaNo = ''
      this.areaName = ''
    },
    addRegion() {
      if (this.areaNo == '' || this.areaName == '') return
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        areaNo: this.areaNo,
        areaName: this.areaName,
        upCompanyId: this.compyValue,
        isDelete: 0,
        saveTime: this.isAdd ? new Date().toISOString : this.rowItem.saveTime,
        saveUserId: JSON.parse(sessionStorage.getItem('userInfo')).id + '',
      }
      var methods = this.isAdd ? post : put
      methods('/api/Area', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: 'success',
              message: this.isAdd ? '添加成功' : '修改成功',
            })
            this.cancle()
            this.getRegionInfo()
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? '添加失败' : '修改失败')
        })
    },
    deleteItem(row, index) {
      this.$confirm('您确认要删除' + row.areaName + '?')
        .then(() => {
          deleteMethod('/api/Area?Id=' + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.regionList.splice(index, 1)
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    editItem(row) {
      this.isAdd = false
      this.rowItem = row
      this.areaName = row.areaName
      this.areaNo = row.areaNo
    },
  },
}
</script>

<style lang="less" scoped>
._Inp {
  width: 240px;
  /deep/ .el-input__inner {
    background-color: #edf0f7 !important;
  }
}

.tow-box {
  display: flex;
  justify-content: center;
  padding-left: 7px;
  > div {
    width: 45%;
    margin-top: 10px;
    padding-left: 25px;
    > p {
      border-left: 5px solid #5a8bff;
      padding-left: 5px;
      margin: 5px 0 15px 0;
    }
    .el-button {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(1) {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(2) {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: transparent !important;
  }
  .el-table {
    border: 1px solid #c2c2c2;
    & i {
      border-radius: 50%;
      border: 1px solid silver;
      padding: 5px;
      margin: 0 5px;
    }
    & i:nth-of-type(1) {
      background-color: #fef9f9;
      color: red;
      border: 1px solid #d3b2b2;
    }
    & i:nth-of-type(2) {
      background-color: #eff3fa;
      color: #5a8bff;
      border: 1px solid #b2c7d3;
    }
  }
  .el-table--fit {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
  }
}
._SelectHeader ._Inp {
  width: 300px !important;
  > span {
    width: 180px !important;
  }
}
</style>
