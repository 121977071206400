import { render, staticRenderFns } from "./RegionManage.vue?vue&type=template&id=b1e97086&scoped=true&"
import script from "./RegionManage.vue?vue&type=script&lang=js&"
export * from "./RegionManage.vue?vue&type=script&lang=js&"
import style0 from "./RegionManage.vue?vue&type=style&index=0&id=b1e97086&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1e97086",
  null
  
)

export default component.exports